import 'styles/reset.css';
import 'styles/utopia.css';
import 'styles/global.css';

import { ApolloProvider } from '@apollo/client/react';
import { UserProvider } from '@auth0/nextjs-auth0';
import { NextPage } from 'next';
import { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import React, { ReactElement, ReactNode } from 'react';

import { getDefaultLayout } from 'components/layouts/default';
import { ISettings, Settings } from 'contexts';
import { siteGlobalsQuery } from 'framework/sanity/queries';
import { useApollo } from 'utils/apollo-client';
import { useSetFeatureFlags } from 'utils/featureFlags';
import { useGtm } from 'utils/gtm/hooks';
import {
  isInPreviewMode,
  getClient,
  usePreviewSubscription,
} from 'utils/sanity';
import theme from 'utils/theme';
import { ISiteGlobals } from 'utils/types';

interface MyAppProps extends AppProps {
  props: {
    settings: ISettings;
    siteGlobals: ISiteGlobals;
  };
  Component: NextPage & {
    getLayout?: (page: ReactElement, siteGlobals: ISiteGlobals) => ReactNode;
  };
}

function MyApp({ Component, pageProps, props }: MyAppProps) {
  const router = useRouter();
  const apolloClient = useApollo(pageProps);

  const { data } = usePreviewSubscription(siteGlobalsQuery, {
    initialData: props,
    enabled: isInPreviewMode(router),
  });

  useGtm();

  useSetFeatureFlags();

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || getDefaultLayout;

  return (
    <UserProvider>
      <ApolloProvider client={apolloClient}>
        <Settings.Provider value={props.settings}>
          <NextNProgress
            color={theme.colors.pink}
            options={{ showSpinner: false }}
          />
          {getLayout(<Component {...pageProps} />, data.siteGlobals)}
        </Settings.Provider>
      </ApolloProvider>
    </UserProvider>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  const { siteGlobals, settings } = await getClient().fetch(siteGlobalsQuery);

  return {
    ...appProps,
    props: { siteGlobals, settings },
  };
};

export default MyApp;
